import { useState } from "react";
import { createContext, useContext } from "react";
import useProvideAuth from './useProvideAuth'

const authContext = createContext();

function ProvideAuth({ children }) {
	const auth = useProvideAuth();
	return (
		<authContext.Provider value={auth}>
			{children}
		</authContext.Provider>
	);
}

function useAuth() {
  return useContext(authContext);
}

const useForceUpdate = () => {
	const [count, setCount] = useState(0)
	
	const increment = () => setCount(prevCount => prevCount + 1)
	return [increment, count]
}

export { ProvideAuth, useAuth, useForceUpdate };